
.s-button-group {
  &--vertical {
    &.flex-wrap {
      .button {
        // margin-block-end:  calc(var(--global-margin) * 0.3)!important;

        &:last-of-type {
          margin-block-end: 0;
        }
      }
    }
  }

  &--horizontal {
    .button {
      //    margin-inline-end:  calc(var(--global-margin) * 0.3)!important;

      &:last-of-type {
        margin-inline-end: 0;
      }
    }

    &.flex-wrap {
      .uk-button {
        //  margin-block-end:  calc(var(--global-margin) * 0.3)!important;

        &:last-of-type {
          margin-block-end: 0;
        }
      }
    }
  }

  &--vertical.s-button-group--tight {
    .button {
      margin-block-end: 0px !important;

      &:last-of-type {
        margin-block-end: 0px;
      }
    }

    &.uk-flex-wrap {
      .button {
        margin-block-end: 0px !important;

        &:last-of-type {
          margin-block-end: 0px;
        }
      }
    }
  }

  &--horizontal.s-button-group--tight {
    .button {
      margin-inline-end: 0px !important;

      &:last-of-type {
        margin-inline-end: 0px;
      }
    }

    &.uk-flex-wrap {
      .button {
        margin-block-end: 0px !important;

        &:last-of-type {
          margin-block-end: 0px;
        }
      }
    }
  }
}


.grid-column {
  // border: 1px solid red;
  flex: v-bind(computedFlex);
  padding: v-bind(computedPadding);
  order: v-bind(computedOrder);

  //TODO: temp
  @media (max-width: 1300px) {
    flex: v-bind(computedFlexXl);
    padding: v-bind(computedPaddingXl);
    order: v-bind(computedOrderXl);
  }

  @media (max-width: 1024px) {
    flex: v-bind(computedFlexL);
    padding: v-bind(computedPaddingL);
    order: v-bind(computedOrderL);
  }

  @media (max-width: 767px) {
    flex: v-bind(computedFlexM);
    padding: v-bind(computedPaddingM);
    order: v-bind(computedOrderM);
  }

  @media (max-width: 499px) {
    flex: v-bind(computedFlexS);
    padding: v-bind(computedPaddingS);
    order: v-bind(computedOrderS);
  }
}

//span, offset, order, responsive, grow


.floating-widget-trigger {
  position: fixed;
  z-index: var(--z-top-1);
  bottom: var(--margin-xl);
  inset-inline-end: var(--margin-xl);
  opacity: 1;
  will-change: opacity;
  transition: opacity 150ms;

  &:hover {
    opacity: 0.8;
  }
}



.notification-item--default-layout {
  color: var(--c-text);
  &.notification-item--is-read {
    color: var(--c-gray-4);
  }
}

.notification-item--intro-layout {
  color: var(--c-text);
  &.notification-item--is-read {
    color: var(--c-gray-4);
  }

  .notification-text{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

  }
}


.preview {
  --image-url-preview-width: 200px;
  width: var(--image-url-preview-width);
  flex: 0 0 var(--image-url-preview-width);
  height: calc(var(--image-url-preview-width) * 9 / 16);
  border-inline-end: 1px solid var(--c-gray-2);
  background-color: var(--c-gray-1);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1) inset;
  border-radius: var(--border-radius-sharp) 0 0 var(--border-radius-sharp);
  @media screen and (max-width: 767px) {
    width: 150px;
    flex: 0 0 150px;
    height: calc(150px * 9 / 16);
  }
}

.video-preview {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.url-input {
  padding-inline: var(--margin-s);
}


.large-option {
  border: 1px solid var(--c-gray);
  margin-bottom: var(--margin-m);
  cursor: pointer;
  background: var(--c-gray-1);
  border-radius: var(--border-radius-s);

  &:last-of-type {
    margin-bottom: 0;
  }

  &.selected {
    border: 1px solid var(--c-lead);
  }

  &:hover {
    border: 1px solid var(--c-gray-5);
  }
}


.floating-pill-nav {
  .uk-subnav {
    margin-bottom: 0;
  }
}

.uk-subnav.wide li a {
  @media screen and (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

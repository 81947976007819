
.item-fields-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-inline-end: var(--margin-l);

  @media (max-width: 1024px) {
    padding-inline-end: var(--margin-m);
  }
}

.field-immediate-wrapper {
  flex: 0 0 calc(50% - (var(--margin-s)));
  min-width: 200px;

  @media (max-width: 1024px) {
    flex-grow: 500;
    flex: 150px;
    min-width: 0;
  }
}

.item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;

  @media (max-width: 767px) {
    flex-direction: column-reverse; // buttons on top, instead of on the side
    gap: var(--margin-m);

    .row-buttons-container {
      width: 100%;

      *:first-child {
        margin-inline-start: auto;
      }
    }
  }
}

.row-buttons-container {
  margin-top: 27px;
  @media (max-width: 767px) {
    margin-top: 0;
    width: 100%;

    *:first-child {
      margin-inline-start: auto;
    }
  }
}

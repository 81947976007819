
.spinner-overlay-wrapper {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: var(--z-top-2);
  background: rgba(255, 255, 255, 0.45);
  display: flex;
  justify-content: center;
  text-align: center;

  &.spinner-overlay-wrapper--absolute {
    position: absolute;
  }

  &.spinner-overlay-wrapper--fixed {
    position: fixed;
  }

  .spinner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    > * {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .spinner-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.spinner-wrapper--inline,
.spinner-wrapper--no-overlay.spinner-wrapper--inline {
  display: inline-block;
  vertical-align: middle;
}

.spinner-wrapper--no-overlay {
  display: flex;

  .inner {
    display: flex;
    align-items: center;

    > * {
      -webkit-margin-inline-end: 0.5em;
      margin-inline-end: 0.5em;

      &:last-child {
        -webkit-margin-inline-end: 0.5em;
        margin-inline-end: 0.5em;
      }
    }
  }
}

.spinner-circle,
.spinner-cubes {
  height: v-bind(heightProperty);
  width: v-bind(widthProperty);
  color: v-bind(computedColor);
}


.grid {
  display: flex;

  flex-wrap: wrap;
  //gap implemented by children with padding
  //TODO: negative margin based on gutter
  margin: v-bind(computedNegativeMargin);

  justify-content: v-bind(computedJustifyContent);
  align-items: v-bind(computedAlignItems);

  @media (max-width: 1300px) {
    margin: v-bind(computedNegativeMarginXl);
    justify-content: v-bind(computedJustifyContentXl);
    align-items: v-bind(computedAlignItemsXl);
  }

  @media (max-width: 1024px) {
    margin: v-bind(computedNegativeMarginL);
    justify-content: v-bind(computedJustifyContentL);
    align-items: v-bind(computedAlignItemsL);
  }

  @media (max-width: 767px) {
    justify-content: v-bind(computedJustifyContentM);
    align-items: v-bind(computedAlignItemsM);
  }

  @media (max-width: 499px) {
    margin: v-bind(computedNegativeMarginS);
    justify-content: v-bind(computedJustifyContentS);
    align-items: v-bind(computedAlignItemsS);
  }
}
